import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  faqs: {
    maxWidth: 722,
    width: 'calc(100% - 24px)',
    margin: '24px auto 0',
    '& .panel': {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
      marginBottom: 24,
      '& button': {
        padding: 24,
        backgroundColor: 'transparent',
        color: 'currentColor',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '24px',
        border: 'none',
        fontFamily: theme.headerFont,
        fontSize: 22,
        lineHeight: '48px',
        fontWeight: 600,
        textAlign: 'left',
        cursor: 'pointer',
        '& i': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          fontSize: 32,
          lineHeight: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 48,
          width: 48,
          height: 48,
          textDecoration: 'none',
          fontStyle: 'normal',
        }
      },
      '& .answer': {
        overflow: 'hidden',
        height: 0,
        opacity: 0,
        transition: 'opacity .3s ease-in-out',
        '& p': {
          maxWidth: 526,
          marginInline: 'auto',
          marginBottom: 0,
        }
      },
      '&.open': {
        '& .answer': {
          opacity: 1,
          height: 'auto',
          padding: '0 24px 24px',
        }
      }
    }
  },
  para: {
    display: "block",
    position: "relative",
    fontFamily: theme.bodyFont,
    "& a": {
      color: theme.palette.secondary.main,
      "&:hover, &:foucs": {
        color: theme.palette.secondary.dark,
      },
    },
  },
}))

export const AcfFaqsBlock = props => {
  const classes = useStyles()
  const faqs = props?.block?.blockFaqs?.faqs

  const [openPanel, setOpenPanel] = useState("")

  return (
    <div
      className={`${classes.faqs} ${
        props.attributes.className ? props.attributes.className : ""
      }`}
    >
      {faqs.map((faq, index) => {
        return (
          <div key={`panel-${index}`} className={`panel ${openPanel === index ? "open" : ""}`}>
            <button
              className={`question`}
              onClick={() =>
                openPanel === index ? setOpenPanel("") : setOpenPanel(index)
              }
              onKeyDown={() =>
                openPanel === index ? setOpenPanel("") : setOpenPanel(index)
              }
            >
              <i className="">
                {openPanel === index ? "-" : "+"}
              </i>
              <span>{faq.question}</span>
            </button>
            <div className={`answer`}>
              <Typography
                    variant="body1"
                    align={"left"}
                    className={props.attributes.className + " " + classes.para}
                  >
                    {faq.answer}
                  </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

AcfFaqsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfFaqsBlockFragment = graphql`
  fragment AcfFaqsBlock on wp_Block {
    ... on wp_AcfFaqsBlock {
      name
      attributes {
        className
      }
      blockFaqs {
        faqs {
          question
          answer
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfFaqsBlockPreview = `
... on AcfFaqsBlock { 
  name
  attributes {
    className
  }
  blockFaqs {
    faqs {
      question
      answer
    }
  } 
}`
