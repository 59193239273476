import React from "react"
import { replaceMasks } from "../services/masks"

import { CoreBlock } from "../components/blocks/CoreBlock"

import { CoreParagraphBlock } from "../components/blocks/CoreParagraphBlock/" // eslint-disable-line
import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock/" // eslint-disable-line
import { CoreColumnBlock } from "../components/blocks/CoreColumnBlock/" // eslint-disable-line
import { CoreColumnsBlock } from "../components/blocks/CoreColumnsBlock/" // eslint-disable-line
import { CoreImageBlock } from "../components/blocks/CoreImageBlock/" // eslint-disable-line
import { CoreButtonsBlock } from "../components/blocks/CoreButtonsBlock/" // eslint-disable-line
import { CoreButtonBlock } from "../components/blocks/CoreButtonBlock/" // eslint-disable-line
import { CoreGalleryBlock } from "../components/blocks/CoreGalleryBlock/" // eslint-disable-line
import { CgbQuoteBlock } from "../components/blocks/CgbQuoteBlock/" // eslint-disable-line
import { CoreListBlock } from "../components/blocks/CoreListBlock/" // eslint-disable-lined
import { CoreListItemBlock } from "../components/blocks/CoreListItemBlock/" // eslint-disable-lined
import { CoreTableBlock } from "../components/blocks/CoreTableBlock/" // eslint-disable-lined
import { CoreFileBlock } from "../components/blocks/CoreFileBlock/" // eslint-disable-lined
import { CoreSpacerBlock } from "../components/blocks/CoreSpacerBlock/" // eslint-disable-lined
import { CgbAccordionBlock } from "../components/blocks/CgbAccordionBlock" // eslint-disable-line
import { CgbAccordionInnerBlock } from "../components/blocks/CgbAccordionInnerBlock" // eslint-disable-line
import { CgbBannerCalloutCtaBlock } from "../components/blocks/CgbBannerCalloutCtaBlock" // eslint-disable-line
import { CgbBannerMessageBlock } from "../components/blocks/CgbBannerMessageBlock" // eslint-disable-line
import { CgbBlogPostWrapBlock } from "../components/blocks/CgbBlogPostWrapBlock" // eslint-disable-line
import { CgbBreakoutGalleryIntroBlock } from "../components/blocks/CgbBreakoutGalleryIntroBlock" // eslint-disable-line
import { CgbCalloutCtaBlock } from "../components/blocks/CgbCalloutCtaBlock" // eslint-disable-line
import { CgbCardBlock } from "../components/blocks/CgbCardBlock" // eslint-disable-line
import { CgbCardRowBlock } from "../components/blocks/CgbCardRowBlock" // eslint-disable-line
import { CgbContactBannerBlock } from "../components/blocks/CgbContactBannerBlock" // eslint-disable-line
import { CgbContactFormBlock } from "../components/blocks/CgbContactFormBlock" // eslint-disable-line
import { CgbFileListBlock } from "../components/blocks/CgbFileListBlock" // eslint-disable-line
import { CgbFileListInnerBlock } from "../components/blocks/CgbFileListInnerBlock" // eslint-disable-line
import { CgbFileListInnerSmallBlock } from "../components/blocks/CgbFileListInnerSmallBlock" // eslint-disable-line
import { CgbGoodToGoBlock } from "../components/blocks/CgbGoodToGoBlock" // eslint-disable-line
import { CgbIconListBlock } from "../components/blocks/CgbIconListBlock" // eslint-disable-line
import { CgbIconListIntroBlock } from "../components/blocks/CgbIconListIntroBlock" // eslint-disable-line
import { CgbIconListInnerBlock } from "../components/blocks/CgbIconListInnerBlock" // eslint-disable-line
import { CgbImageSliderBlock } from "../components/blocks/CgbImageSliderBlock" // eslint-disable-line
import { CgbImageBreakdownBlock } from "../components/blocks/CgbImageBreakdownBlock" // eslint-disable-line
import { CgbImageBreakdownInnerBlock } from "../components/blocks/CgbImageBreakdownInnerBlock" // eslint-disable-line
import { CgbImageGalleryGridBlock } from "../components/blocks/CgbImageGalleryGridBlock" // eslint-disable-line
import { CgbImageGalleryGridInnerLargeBlock } from "../components/blocks/CgbImageGalleryGridInnerLargeBlock" // eslint-disable-line
import { CgbImageGalleryGridInnerSmallBlock } from "../components/blocks/CgbImageGalleryGridInnerSmallBlock" // eslint-disable-line
import { CgbImageLinkSliderBlock } from "../components/blocks/CgbImageLinkSliderBlock" // eslint-disable-line
import { CgbImageLinkSliderInnerBlock } from "../components/blocks/CgbImageLinkSliderInnerBlock" // eslint-disable-line
import { CgbIntroGalleryBlock } from "../components/blocks/CgbIntroGalleryBlock" // eslint-disable-line
import { CgbIntroGallerySplitBlock } from "../components/blocks/CgbIntroGallerySplitBlock" // eslint-disable-line
import { CgbIntroImageSliderBlock } from "../components/blocks/CgbIntroImageSliderBlock" // eslint-disable-line
import { CgbLatestPostsBlock } from "../components/blocks/CgbLatestPostsBlock" // eslint-disable-line
import { CgbNextPreviousPostsBlock } from "../components/blocks/CgbNextPreviousPostsBlock" // eslint-disable-line
import { CgbOfferGridBlock } from "../components/blocks/CgbOfferGridBlock" // eslint-disable-line
import { CgbOfferGridInnerBlock } from "../components/blocks/CgbOfferGridInnerBlock" // eslint-disable-line
import { CgbOpeningTimesBlock } from "../components/blocks/CgbOpeningTimesBlock" // eslint-disable-line
import { CgbPullquoteBlock } from "../components/blocks/CgbPullquoteBlock" // eslint-disable-line
import { CgbPubListingBlock } from "../components/blocks/CgbPubListingBlock" // eslint-disable-line
import { CgbSocialSharingBlock } from "../components/blocks/CgbSocialSharingBlock" // eslint-disable-line
import { CgbThinIntroBlock } from "../components/blocks/CgbThinIntroBlock" // eslint-disable-line
import { CgbThickIntroBlock } from "../components/blocks/CgbThickIntroBlock" // eslint-disable-line
import { CgbTwoCardIntroBlock } from "../components/blocks/CgbTwoCardIntroBlock" // eslint-disable-line
import { CgbTwoColIntroBlock } from "../components/blocks/CgbTwoColIntroBlock" // eslint-disable-line
import { CgbBlogPagePostsBlock } from "../components/blocks/CgbBlogPagePostsBlock" // eslint-disable-line
import { CgbVideoBannerMessageBlock } from "../components/blocks/CgbVideoBannerMessageBlock" // eslint-disable-line
import { CgbVideoEmbedBlock } from "../components/blocks/CgbVideoEmbedBlock" // eslint-disable-line
import { CgbAccommodationBannerBlock } from "../components/blocks/CgbAccommodationBannerBlock" // eslint-disable-line
import { CgbAccommodationFeaturedRoomsBlock } from "../components/blocks/CgbAccommodationFeaturedRoomsBlock" // eslint-disable-line
import { CgbAccommodationFeaturedRoomsInnerBlock } from "../components/blocks/CgbAccommodationFeaturedRoomsInnerBlock" // eslint-disable-line
import { CgbAccommodationIntroBlock } from "../components/blocks/CgbAccommodationIntroBlock" // eslint-disable-line
import { CgbAccommodationRoomListBlock } from "../components/blocks/CgbAccommodationRoomListBlock" // eslint-disable-line
import { CgbAccommodationOtherRoomsBlock } from "../components/blocks/CgbAccommodationOtherRoomsBlock" // eslint-disable-line
import { GravityformsFormBlock } from "../components/blocks/GravityformsFormBlock" // eslint-disable-line
import { AcfAwardRowBlock } from "../components/blocks/AcfAwardRowBlock"
import { AcfBrewGalleryBlock } from "../components/blocks/AcfBrewGalleryBlock"
import { AcfContactFormWeddingBlock } from "../components/blocks/AcfContactFormWeddingBlock"
import { AcfFaqsBlock } from "../components/blocks/AcfFaqsBlock"
import { AcfGridSliderGalleryBlock } from "../components/blocks/AcfGridSliderGalleryBlock"
import { AcfIconRowBlock } from "../components/blocks/AcfIconRowBlock"
import { AcfIconRowInnerBlock } from "../components/blocks/AcfIconRowInnerBlock"
import { AcfIframeEmbedBlock } from "../components/blocks/AcfIframeEmbedBlock"
import { AcfImageContentSplitBlock } from "../components/blocks/AcfImageContentSplitBlock"
import { AcfIntroGalleryMixedBlock } from "../components/blocks/AcfIntroGalleryMixedBlock"
import { AcfIntroGalleryTwoMixedBlock } from "../components/blocks/AcfIntroGalleryTwoMixedBlock"
import { AcfIntroGalleryPortraitBlock } from "../components/blocks/AcfIntroGalleryPortraitBlock"
import { AcfIntroGallerySquareBlock } from "../components/blocks/AcfIntroGallerySquareBlock"
import { AcfIssuuBlock } from "../components/blocks/AcfIssuuBlock"
import { AcfTestimonialSliderBlock } from "../components/blocks/AcfTestimonialSliderBlock"
import { AcfTestimonialSliderInnerBlock } from "../components/blocks/AcfTestimonialSliderInnerBlock"
import { AcfTextBackgroundBlock } from "../components/blocks/AcfTextBackgroundBlock"
import { AcfTimedContainerBlock } from "../components/blocks/AcfTimedContainerBlock"
import { AcfTwoColumnStackedBlock } from "../components/blocks/AcfTwoColumnStackedBlock"
import { AcfVideoIntroBlock } from "../components/blocks/AcfVideoIntroBlock"
import { CgbSignupGateFormBlock } from "../components/blocks/CgbSignupGateFormBlock"
import { AcfTenKitesMenusBlock } from "../components/blocks/AcfTenKitesMenusBlock"

/**
 * Returns the component for the block name
 * @param {String} name
 */
function mapBlocks(name) {
  let block = null
  switch (name) {
    case "CoreBlock":
      block = CoreBlock
      break
    case "AcfAwardRowBlock":
      block = AcfAwardRowBlock
      break
    case "AcfBrewGalleryBlock":
      block = AcfBrewGalleryBlock
      break
    case "AcfContactFormWeddingBlock":
      block = AcfContactFormWeddingBlock
      break
    case "AcfFaqsBlock":
      block = AcfFaqsBlock
      break
    case "AcfGridSliderGalleryBlock":
      block = AcfGridSliderGalleryBlock
      break
    case "AcfIconRowBlock":
      block = AcfIconRowBlock
      break
    case "AcfIconRowInnerBlock":
      block = AcfIconRowInnerBlock
      break
    case "AcfIframeEmbedBlock":
      block = AcfIframeEmbedBlock
      break
    case "AcfImageContentSplitBlock":
      block = AcfImageContentSplitBlock
      break
    case "AcfIntroGalleryMixedBlock":
      block = AcfIntroGalleryMixedBlock
      break
    case "AcfIntroGalleryTwoMixedBlock":
      block = AcfIntroGalleryTwoMixedBlock
      break
    case "AcfIntroGalleryPortraitBlock":
      block = AcfIntroGalleryPortraitBlock
      break
    case "AcfIntroGallerySquareBlock":
      block = AcfIntroGallerySquareBlock
      break
    case "AcfIssuuBlock":
      block = AcfIssuuBlock
      break
    case "AcfTenKitesMenusBlock":
      block = AcfTenKitesMenusBlock
      break
    case "AcfTestimonialSliderBlock":
      block = AcfTestimonialSliderBlock
      break
    case "AcfTestimonialSliderInnerBlock":
      block = AcfTestimonialSliderInnerBlock
      break
    case "AcfTextBackgroundBlock":
      block = AcfTextBackgroundBlock
      break
    case "AcfTimedContainerBlock":
      block = AcfTimedContainerBlock
      break
    case "AcfTwoColumnStackedBlock":
      block = AcfTwoColumnStackedBlock
      break
    case "AcfVideoIntroBlock":
      block = AcfVideoIntroBlock
      break
    case "CoreParagraphBlock":
      block = CoreParagraphBlock
      break
    case "CoreHeadingBlock":
      block = CoreHeadingBlock
      break
    case "CoreColumnBlock":
      block = CoreColumnBlock
      break
    case "CoreColumnsBlock":
      block = CoreColumnsBlock
      break
    case "CoreImageBlock":
      block = CoreImageBlock
      break
    case "CoreButtonsBlock":
      block = CoreButtonsBlock
      break
    case "CoreButtonBlock":
      block = CoreButtonBlock
      break
    case "CoreGalleryBlock":
      block = CoreGalleryBlock
      break
    case "CgbQuoteBlock":
      block = CgbQuoteBlock
      break
    case "CoreListBlock":
      block = CoreListBlock
      break
    case "CoreListItemBlock":
      block = CoreListItemBlock
      break
    case "CoreTableBlock":
      block = CoreTableBlock
      break
    case "CoreFileBlock":
      block = CoreFileBlock
      break
    case "CoreSpacerBlock":
      block = CoreSpacerBlock
      break
    case "CgbAccordionBlock":
      block = CgbAccordionBlock
      break
    case "CgbAccordionInnerBlock":
      block = CgbAccordionInnerBlock
      break
    case "CgbBannerCalloutCtaBlock":
      block = CgbBannerCalloutCtaBlock
      break
    case "CgbBannerMessageBlock":
      block = CgbBannerMessageBlock
      break
    case "CgbBlogPostWrapBlock":
      block = CgbBlogPostWrapBlock
      break
    case "CgbBreakoutGalleryIntroBlock":
      block = CgbBreakoutGalleryIntroBlock
      break
    case "CgbCalloutCtaBlock":
      block = CgbCalloutCtaBlock
      break
    case "CgbCardBlock":
      block = CgbCardBlock
      break
    case "CgbCardRowBlock":
      block = CgbCardRowBlock
      break
    case "CgbContactBannerBlock":
      block = CgbContactBannerBlock
      break
    case "CgbContactFormBlock":
      block = CgbContactFormBlock
      break
    case "CgbFileListBlock":
      block = CgbFileListBlock
      break
    case "CgbFileListInnerBlock":
      block = CgbFileListInnerBlock
      break
    case "CgbFileListInnerSmallBlock":
      block = CgbFileListInnerSmallBlock
      break
    case "CgbGoodToGoBlock":
      block = CgbGoodToGoBlock
      break
    case "CgbIconListBlock":
      block = CgbIconListBlock
      break
    case "CgbIconListIntroBlock":
      block = CgbIconListIntroBlock
      break
    case "CgbIconListInnerBlock":
      block = CgbIconListInnerBlock
      break
    case "CgbImageSliderBlock":
      block = CgbImageSliderBlock
      break
    case "CgbImageBreakdownBlock":
      block = CgbImageBreakdownBlock
      break
    case "CgbImageBreakdownInnerBlock":
      block = CgbImageBreakdownInnerBlock
      break
    case "CgbImageGalleryGridBlock":
      block = CgbImageGalleryGridBlock
      break
    case "CgbImageGalleryGridInnerLargeBlock":
      block = CgbImageGalleryGridInnerLargeBlock
      break
    case "CgbImageGalleryGridInnerSmallBlock":
      block = CgbImageGalleryGridInnerSmallBlock
      break
    case "CgbImageLinkSliderBlock":
      block = CgbImageLinkSliderBlock
      break
    case "CgbImageLinkSliderInnerBlock":
      block = CgbImageLinkSliderInnerBlock
      break
    case "CgbIntroGalleryBlock":
      block = CgbIntroGalleryBlock
      break
    case "CgbIntroGallerySplitBlock":
      block = CgbIntroGallerySplitBlock
      break
    case "CgbIntroImageSliderBlock":
      block = CgbIntroImageSliderBlock
      break
    case "CgbLatestPostsBlock":
      block = CgbLatestPostsBlock
      break
    case "CgbNextPreviousPostsBlock":
      block = CgbNextPreviousPostsBlock
      break
    case "CgbOfferGridBlock":
      block = CgbOfferGridBlock
      break
    case "CgbOfferGridInnerBlock":
      block = CgbOfferGridInnerBlock
      break
    case "CgbOpeningTimesBlock":
      block = CgbOpeningTimesBlock
      break
    case "CgbPullquoteBlock":
      block = CgbPullquoteBlock
      break
    case "CgbPubListingBlock":
      block = CgbPubListingBlock
      break
    case "CgbSocialSharingBlock":
      block = CgbSocialSharingBlock
      break
    case "CgbThinIntroBlock":
      block = CgbThinIntroBlock
      break
    case "CgbThickIntroBlock":
      block = CgbThickIntroBlock
      break
    case "CgbTwoCardIntroBlock":
      block = CgbTwoCardIntroBlock
      break
    case "CgbTwoColIntroBlock":
      block = CgbTwoColIntroBlock
      break
    case "CgbBlogPagePostsBlock":
      block = CgbBlogPagePostsBlock
      break
    case "CgbVideoBannerMessageBlock":
      block = CgbVideoBannerMessageBlock
      break
    case "CgbVideoEmbedBlock":
      block = CgbVideoEmbedBlock
      break
    case "CgbAccommodationBannerBlock":
      block = CgbAccommodationBannerBlock
      break
    case "CgbAccommodationFeaturedRoomsBlock":
      block = CgbAccommodationFeaturedRoomsBlock
      break
    case "CgbAccommodationFeaturedRoomsInnerBlock":
      block = CgbAccommodationFeaturedRoomsInnerBlock
      break
    case "CgbAccommodationIntroBlock":
      block = CgbAccommodationIntroBlock
      break
    case "CgbAccommodationRoomListBlock":
      block = CgbAccommodationRoomListBlock
      break
    case "CgbAccommodationOtherRoomsBlock":
      block = CgbAccommodationOtherRoomsBlock
      break
    case "GravityformsFormBlock":
      block = GravityformsFormBlock
      break
    case "CgbSignupGateFormBlock":
      block = CgbSignupGateFormBlock
      break

    default:
      block = null
      break
  }
  return block
}

function UCFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Converts the block name in to a component name
 * eg. core/banner = CoreBannerBlock
 * @param {String} name
 */
function convertName(name) {
  const split = name.split("/")
  const parts = []
  parts.push(UCFirst(split[0]))
  const split2 = split[1].split("-")
  split2.map(part => {
    parts.push(UCFirst(part))
  })
  return parts.join("") + (name === "core/block" ? "" : "Block")
}

export const buildBlocks = (
  blocks,
  parentAttributes = {},
  pageContext = {}
) => {
  if (!blocks) return []
  // Render the blocks
  let processed = []
  blocks.map((block, index) => {
    if (!block.name) return false
    block.attributes = replaceMasks(block.attributes)
    let elem = null

    // Convert the block name to a component name
    const component = convertName(block.name)
    let OutputBlock = null

    OutputBlock = mapBlocks(component)

    elem = OutputBlock ? (
      <OutputBlock
        key={index}
        attributes={block.attributes}
        attributesJSON={block.attributesJSON ?? null}
        parentAttributes={parentAttributes}
        originalContent={block.originalContent}
        innerBlocks={
          component === "CoreBlock"
            ? block.reusableBlock.blocks
            : block.innerBlocks
        }
        pageContext={pageContext}
        block={block}
      />
    ) : null

    return processed.push(elem)
  })
  return processed
}
